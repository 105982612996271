<template>
  <v-container fluid>
    <CRUD v-if="!id" ref="crud" id-method="params" :fields="fields" :table="table" :api="api">
      <template v-slot:[`table.col.HoursApplied`]="{ item }">
        <v-btn text small @click="goTo(item.NRIC)">
          List
        </v-btn>
      </template>
    </CRUD>
    <v-card v-else :loading="agentTable.loading">
      <v-toolbar short>
        <v-btn icon @click="back">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Holidays Applied for {{ agentTable.info.TNAME }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-btn
          v-for="(action, index) in agentTable.actions"
          :key="index"
          outlined
          :disabled="
            (action.name == 'delete' && agentTable.selected.length == 0) ||
              (action.name == 'add' && agentTable.info.availHours == 0)
          "
          class="ml-2"
          @click="onActionClick(action.name)"
        >
          <v-icon left>
            {{ action.icon }}
          </v-icon>
          {{ action.text }}
        </v-btn>
        <v-btn
          v-if="agentTable.info.availHours > 0 && agentTable.info.endDate"
          :loading="agentTable.paying"
          outlined
          class="ml-2"
          @click="onActionClick('payout')"
        >
          <v-icon left>
            mdi-cash-refund
          </v-icon>
          Payout
        </v-btn>
      </v-toolbar>
      <v-card-text>
        Available hours for vacation by {{ agentTable.info.TNAME }}:
        <b>{{ agentTable.info.availHours }}</b>
      </v-card-text>
      <v-data-table
        v-model="agentTable.selected"
        :options="{ itemsPerPage: -1 }"
        item-key="RequestID"
        hide-default-footer
        show-select
        :headers="agentTable.headers"
        :items="deletableAgentItems"
      >
        <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
          <v-simple-checkbox
            :ripple="false"
            :value="isSelected"
            :disabled="!item.deletable"
            @input="select($event)"
          ></v-simple-checkbox>
        </template>
        <template v-slot:[`item.Hours`]="props">
          <v-edit-dialog
            large
            @save="onEditSave(props.item)"
            @open="form.currentEditVal = props.item.Hours"
          >
            {{ props.item.Hours }}
            <template v-slot:input>
              <v-text-field
                v-model="form.currentEditVal"
                :rules="form.hoursRules"
                type="number"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </v-card>

    <CRUDForm
      ref="crudForm"
      @save="onSave"
      :alwaysPostAll="true"
      @close="form.active = false"
      :formSize="500"
      headerText="Add Holiday"
      :formActive="form.active"
      :actionBtnName="form.step == 1 ? 'Next' : 'Save'"
    >
      <template v-slot:[`form.content`]="">
        <template v-if="form.step == 1">
          <div class="subtitle-2">Step 1: Please choose the vacation date range.</div>
          <v-row class="py-5">
            <v-col cols="12" v-for="input in form.inputs" :key="input.name">
              <InputControl
                :label="input.text"
                :value.sync="form.values[input.name]"
                attrType="D"
                :rules="['mandatory']"
              />
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <div class="subtitle-2">
            Step 2: Please enter vacation hours in the textbox and save the form.
          </div>
          <v-row class="py-5">
            <v-col cols="12">
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="(header, i) in form.headers" :key="i">
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in form.items" :key="i">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.dayOfWeek }}</td>
                      <td>{{ item.date }}</td>
                      <td width="160px">
                        <v-text-field
                          hide-details="auto"
                          dense
                          type="number"
                          v-model="form.values.hrs[item.date]"
                          :rules="form.hoursRules"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <div class="caption">
            *Weekends and vacation already existed are omitted.
          </div>
        </template>
      </template>
    </CRUDForm>
  </v-container>
</template>

<script>
//import axios from "axios";
import CRUD from "@/components/util/CRUD/CRUD";
import CRUDForm from "@/components/util/CRUD/CRUDForm";
import InputControl from "@/components/util/InputControl";

export default {
  components: {
    CRUD,
    CRUDForm,
    InputControl
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v) this.fetchAgentHoliday();
      }
    }
  },
  computed: {
    deletableAgentItems() {
      return this.agentTable.items.map((x) => ({ ...x, isSelectable: x.deletable }));
    },
    id() {
      return this.$route.params.id;
    }
  },
  data() {
    return {
      api: {
        url: "/ccms/holiday/"
      },
      table: {
        text: `Holiday Tracker (${this.$store.state.auth.user.areaid})`,
        key: "NRIC",
        default: {
          itemsPerPage: -1,
          sortBy: ["TNAME"],
          sortDesc: [true],
          attrs: { "hide-default-footer": true, "show-select": false }
        },
        components: {
          filter: false,
          delete: false,
          add: false,
          quickFilter: [
            { name: "TNAME", type: "text", width: "400px" },
            { name: "Year", type: "number", value: new Date().toJSON().slice(0, 4) }
          ]
        }
      },
      fields: [
        {
          text: "Name",
          name: "TNAME",
          searchable: true
        },
        {
          text: "Hours Worked",
          name: "HoursWorked"
        },
        {
          text: "Hours Accrued",
          name: "HoursAccrued"
        },
        {
          text: "Hours Carried Forward",
          name: "HolidayCarriedForward"
        },
        {
          text: "Hours Used",
          name: "HoursUsed"
        },
        {
          text: "Hours Available",
          name: "HoursAvail"
        },
        {
          text: "Hours Applied",
          name: "HoursApplied"
        },
        {
          text: "Year",
          name: "Year",
          listable: false
        }
      ],
      agentTable: {
        info: { availHours: 0, TNAME: "-" },
        actions: [
          { text: "Delete", name: "delete", icon: "mdi-close" },
          { text: "Add", name: "add", icon: "mdi-plus" }
        ],
        paying: false,
        loading: false,
        selected: [],
        headers: [
          {
            text: "Request Date",
            value: "RequestDate"
          },
          {
            text: "Vacation Date",
            value: "RequestDate"
          },
          {
            text: "Day of Week",
            value: "dayOfWeek"
          },
          {
            text: "Vacation Hours",
            value: "Hours"
          },
          {
            text: "Approved By",
            value: "FULLNAME"
          }
        ],
        items: []
      },
      form: {
        currentEditVal: null,
        hoursRules: [
          (value) => !!value || "Required",
          (value) => value < 9 || "Maximum vacation hours per day is 8."
        ],
        values: {
          DATE_FROM: "2021-08-18",
          DATE_TO: "2021-08-21"
        },
        headers: ["#", "Day of Week", "Vacation Date", "Vacation Hours"],
        step: 1,
        active: false,
        inputs: [
          { name: "DATE_FROM", text: "Start Date" },
          { name: "DATE_TO", text: "End Date" }
        ],
        items: []
      }
    };
  },
  methods: {
    onActionClick(e) {
      switch (e) {
        case "delete":
          this.delete();
          break;
        case "payout":
          this.payout();
          break;
        case "add":
          this.form.values = {
            DATE_FROM: null,
            DATE_TO: null
          };
          this.form.active = true;
          break;
      }
    },
    back() {
      this.$router.push({ params: { id: null } });
    },
    goTo(nric) {
      this.$router.push({ params: { id: nric } });
    },
    onEditSave(e) {
      this.agentTable.loading = true;
      this.$axios
        .put("/ccms/holiday/update", {
          hrs: this.form.currentEditVal,
          nric: this.id,
          id: e.RequestID
        })
        .then((res) => {
          if (res.data.error) {
            this.$store.commit("sendAlert", {
              msg: res.data.error,
              color: "error"
            });
          } else {
            e.Hours = this.form.currentEditVal;
            this.agentTable.info.availHours = res.data.availHours;
          }
        })
        .finally(() => {
          this.agentTable.loading = false;
        });
    },
    payout() {
      this.agentTable.paying = false;
      this.$root
        .$confirm(
          "Confirm payout",
          `This will payout all remaining unused holiday hours (${this.agentTable.info.availHours}) for the agent on the last day of employment (${this.agentTable.info.endDate}). Continue?`,
          {
            color: "red"
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.$axios
              .post("/ccms/holiday/payout", {
                nric: this.id,
                endDate: this.agentTable.info.endDate,
                hrs: this.agentTable.info.availHours
              })
              .then((res) => {
                if (res.data.status) {
                  this.$store.commit("sendAlert", {
                    msg: "All unused holiday hours have been used for this agent.",
                    color: "success"
                  });
                } else {
                  this.$store.commit("sendAlert", {
                    msg: res.data.error,
                    color: "error"
                  });
                }
              })
              .finally(() => {
                this.agentTable.paying = false;
                this.fetchAgentHoliday();
              });
          }
        });
    },
    delete() {
      let idsToDelete = this.agentTable.selected.map((e) => {
        return e["RequestID"];
      });

      this.$root
        .$confirm("Confirm Delete", "Delete selected holidays?", {
          color: "red"
        })
        .then((confirm) => {
          if (confirm) {
            this.$axios
              .delete("/ccms/holiday/delete", {
                params: {
                  nric: this.id,
                  ids: idsToDelete
                }
              })
              .then(() => {
                this.fetchAgentHoliday();
                this.agentTable.selected = [];
              });
          }
        });
    },
    onSave() {
      if (this.form.step == 1) {
        this.generateDates();
      } else {
        this.$axios
          .post("/ccms/holiday/save", {
            nric: this.id,
            data: this.form.values.hrs
          })
          .then((res) => {
            this.$store.commit("sendAlert", {
              msg: "Holiday added successfully",
              color: "success"
            });
            if (res.data.error) {
              this.$store.commit("sendAlert", {
                msg: res.data.error,
                color: "error"
              });
            } else {
              this.fetchAgentHoliday();
              this.form.active = false;
              this.form.step = 1;
              this.$store.commit("sendAlert", {
                msg: "Holiday added successfully",
                color: "success"
              });
            }
          })
          .finally(() => {
            this.$refs.crudForm.saved();
          });
      }
    },
    generateDates() {
      this.$axios
        .get("/ccms/holiday/generate_dates", {
          params: {
            from: this.form.values.DATE_FROM,
            to: this.form.values.DATE_TO,
            nric: this.id
          }
        })
        .then((res) => {
          if (res.data.items.length > 0) {
            this.form.values.hrs = res.data.values;
            this.form.items = res.data.items;
            this.form.step = 2;
          } else {
            this.$store.commit("sendAlert", {
              msg: "No dates for this date range",
              color: "error"
            });
          }
        })
        .finally(() => {
          this.$refs.crudForm.saved();
        });
    },
    fetchAgentHoliday() {
      this.agentTable.loading = true;
      this.$axios
        .get("/ccms/holiday/get_agent_holidays", {
          params: {
            nric: this.id
          }
        })
        .then((res) => {
          this.agentTable.items = res.data.items;
          this.agentTable.info = res.data.info;
        })
        .finally(() => {
          this.agentTable.loading = false;
        });
    }
  }
};
</script>
